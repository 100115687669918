<template>
  <base-modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div></div>

      <div class="modal__content">
        <div class="modal__header">
          <h2 class="modal__title">
            {{ form.id ? 'Editar Cliente' : 'Novo Cliente' }}
          </h2>
        </div>
        <div class="modal__form--group">
          <p class="subtitle_form">
            Informações Pessoais:
          </p>
          <r-input v-model="form.name" label="Nome" class="col-12" />
          <r-input
            v-model="form.phone"
            :mask="['(##) # ####-####']"
            label="Telefone"
            class="col-12"
          />

          <r-input v-model="form.email" label="Email" class="col-12" />

          <r-select
            label="Estado"
            v-model="form.province_id"
            :items.sync="provinces"
            class="col-12"
            labelName="name"
            placeholder="Selecione o estado"
          />
          <r-select
            label="Cidade"
            v-model="form.city_id"
            :items.sync="cities"
            labelName="name"
            class="col-12"
            :placeholder="'Selecionar cidade'"
          />
          <div class="footer_modal">
            <button type="button" @click="register()" class="button_outline">
              {{ form.id ? 'Atualizar' : 'Cadastrar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import swal from 'sweetalert'

const defaultForm = {
  id: '',
  type: 'PF',
  city_id: null,
  province_id: null,
  name: '',
  email: '',
  phone: '',
  company_id: 1
}
export default {
  data: () => ({
    form: { ...defaultForm },
    cities: []
  }),

  methods: {
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    ...mapActions('client', { save: 'save', fetchMaterials: 'fetch' }),
    async register () {
      if (!this.valid) {
        return swal({
          title: 'Atenção!',
          text: 'Preencha todos os campos para salvar o registro.',
          icon: 'error',
          button: 'Ok'
        })
      }

      this.loading = true
      try {
        const id = this.form.id
        const url = `clients${id ? '/' + id : ''}`
        const method = id ? 'PUT' : 'POST'

        await axios.request({ method, url, data: this.form })

        swal({
          title: 'Sucesso!',
          text: `Cliente ${id ? 'atualizado' : 'cadastrado'} com sucesso.`,
          icon: 'success',
          timer: 4000
        }).then(() => {
          this.form = { ...defaultForm }
          this.$emit('change-modal', false)
          this.$emit('reload-list', true)
        })
      } catch (error) {
        await swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    parseData () {
      const data = new FormData()

      Object.keys(this.form).map(key => {
        const item = this.form[key]
        if (Array.isArray(item)) {
          let count = 0
          item.map(subitem => {
            Object.keys(subitem).map(ik => {
              data.append(`${key}[${count}][${ik}]`, subitem[ik])
            })
            count++
          })
        } else {
          data.append(key, item)
        }
      })
      return data
    }
  },

  computed: {
    ...mapGetters('unity', ['unities']),
    ...mapGetters('item', ['items']),
    ...mapGetters('province', ['provinces']),

    valid () {
      const form = this.form
      if (!form.name) return false
      return true
    }
  },
  props: {
    modal: Boolean,
    editing: Object
  },
  created () {
    this.fetchProvinces()
  },
  components: {
    baseModal: () => import('@/components/modal'),
    RSelect: () => import('@/components/select.vue'),
    RInput: () => import('@/components/input.vue')
  },
  watch: {
    async 'form.province_id' (val) {
      this.cities = []
      if (!val) return
      const url = `cities?province_id=${val}`
      const { data } = await this.$http.get(url)
      this.cities = data
      if (!this.cities.map(i => i.id).includes(this.form.city_id)) {
        this.form.city_id = ''
      }
    },
    editing (val) {
      this.form = { ...val }
    },
    modal (val) {
      if (!val) setTimeout(() => (this.form = { ...defaultForm }), 200)
    }
  }
}
</script>

<style></style>
